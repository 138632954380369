import axios from '@/libs/axios'

export default class WareHouses {
    
    /**
     * Function to warehouses
     * @return Promise
     */
    index() {
        return axios.get(`/Almacen/ObtenerAlmacen`);
    }

    getByCompanyId(idEmpresa) {
        return axios.get(`/Almacen/ObtenerAlmacenPorEmpresa/${idEmpresa}`);    
    }

    /**
     * Obtener ultima fecha de actualizacion de la tabla de jmrs_rotaciones_arts
     * @param {*} warehouse_id 
     */
    getLastUpdateJmrsRotacionesArts(warehouse_id) {
        return axios.get(`/JmrsRotacionesArts/GetJmrsRotacionesArts`)    
    }
}