<script>
import * as icons from 'vue-feather-icons'

export default {
  name: 'FeatherIcon',
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: String,
      default: '14',
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary',
    },
    animation: {
      type: String,
      default: null,
    },
  },
  render(h, { props, data }) {
    // Create Feather Icon
    const svg = h(icons[props.icon], { 
      props: { size: props.size }, 
      class: [props.animation ? props.animation : '', 'feather'],
      ...data })

    // If no badge is provided => Render just SVG
    if (!props.badge) return svg

    // Badge
    const badgeEl = h('span', { staticClass: 'badge badge-up badge-pill', class: props.badgeClasses }, [props.badge])

    // Return span with children
    return h('span', { staticClass: 'feather-icon position-relative' }, [svg, badgeEl])
  },
}
</script>

<style lang="scss">
svg.feather {
  outline: none;
  transition: transform 0.3s ease;
}

/* Ejemplo de animación de rotación */
.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ejemplo de animación de pulso */
.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
