import store from "@/store"

export default [
  {
    path: '/apps/routings',
    name: 'apps-routings',
    component: () => import('@/views/pages/routings/Index.vue'),
    meta: {
      requiresAuth: true,
      permission: 18,
      pageTitle: 'Enrutamiento de Pedidos',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: "/",
        },
        {
          text: 'Enrutamientos',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/routing-logistics',
    name: 'app-logistics',
    component: () => import('@/views/pages/routings/Logistics.vue'),
    meta: {
      requiresAuth: true,
      permission: 14,
      pageTitle: 'Enrutamiento Logística',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: "/",
        },
        {
          text: 'Logística',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/routings-outputs',
    name: 'apps-routings-outputs',
    component: () => import('@/views/pages/routings/Outputs.vue'),
    meta: {
      requiresAuth: true,
      permission: 30,
      pageTitle: 'Enrutamiento -Salidas',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: "/",
        },
        {
          text: 'Salidas',
          active: true,
        },
      ],
    },
  },
]
