export default [
    {
        path: '/apps/customers/credit-limits',
        name: 'apps-customers-credit-limits',
        component: () => import('@/views/pages/customers/CreditLimits.vue'),
        meta: {
            requiresAuth: true,
            permission: 3,
            pageTitle: 'Límite de Créditos',
            breadcrumb: [
              {
                text: 'Dashboard',
                to: "/",
              },
              {
                text: 'Clientes',
                active: true,
              },
            ],
        },
    },
    {
      path: '/apps/reports/credit-limits',
      name: 'apps-reports-credit-limits',
      component: () => import('@/views/pages/reports/CreditLimits.vue'),
      meta: {
          requiresAuth: true,
          permission: 17,
          pageTitle: 'Reporte de Límite de Créditos',
          breadcrumb: [
            {
              text: 'Dashboard',
              to: "/",
            },
            {
              text: 'Límite de Créditos',
              active: true,
            },
          ],
      },
  },
]
