import store from "@/store"

export default [
	{
		path: '/apps/reports/price-changes',
		name: 'apps-reports-price-changes',
		component: () => import('@/views/pages/reports/PriceChanges.vue'),
		meta: {
			requiresAuth: true,
			permission: 10,
			pageTitle: 'Reporte de Cambios de Precios',
			breadcrumb: [
				{
					text: 'Dashboard',
					to: "/",
				},
				{
					text: 'Reportes',
					active: true,
				},
			],
		},
	},
	{
		path: '/apps/reports/price-list',
		name: 'apps-reports-price-list',
		component: () => import('@/views/pages/reports/PriceList.vue'),
		meta: {
			requiresAuth: true,
			permission: 13,
			pageTitle: 'Reporte de Lista de Precios',
			breadcrumb: [
				{
					text: 'Dashboard',
					to: "/",
				},
				{
					text: 'Reportes',
					active: true,
				},
			],
		},
	},
	{
		path: '/apps/reports/routing-logs',
		name: 'apps-reports/routing-logs',
		component: () => import('@/views/pages/reports/RoutingLogs.vue'),
		meta: {
			requiresAuth: true,
			permission: 31,
			pageTitle: 'Bitácora de Enrutamiento',
			breadcrumb: [
				{
					text: 'Dashboard',
					to: "/",
				},
				{
					text: 'Reportes',
					active: true,
				},
			],
		},
	},
	{
		path: '/apps/reports/transfers-list',
		name: 'apps-reports/transfers-list',
		component: () => import('@/views/pages/reports/TransferList.vue'),
		meta: {
			requiresAuth: true,
			permission: 32,
			pageTitle: 'Traspasos Entre Almacenes',
			breadcrumb: [
				{
					text: 'Dashboard',
					to: "/",
				},
				{
					text: 'Reportes',
					active: true,
				},
			],
		},
	},
	{
		path: '/apps/reports/routing-app-carrito',
		name: 'apps-reports/routing-app-carrito',
		component: () => import('@/views/pages/reports/RoutingAppCarrito.vue'),
		meta: {
			requiresAuth: true,
			permission: 33,
			pageTitle: 'Salidas App Vs Carrito',
			breadcrumb: [
				{
					text: 'Dashboard',
					to: "/",
				},
				{
					text: 'Reportes',
					active: true,
				},
			],
		},
	},

	{
		path: '/apps/reports/routings',
		name: 'apps-reports/routings',
		component: () => import('@/views/pages/reports/Routings.vue'),
		meta: {
			requiresAuth: true,
			permission: 40,
			pageTitle: 'Reporte de Rutas',
			breadcrumb: [
				{
					text: 'Dashboard',
					to: "/",
				},
				{
					text: 'Reportes',
					active: true,
				},
			],
		},
	},
]
