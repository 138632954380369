import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import pages from './routes/pages'
import customers from './routes/customers'
import routings from './routes/routings'
import reports from './routes/reports'
import inventories from './routes/inventories'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...customers,
    ...routings,
    ...reports,
    ...inventories,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        requiresAuth: true,
        permission: 0,
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
            active: true,
          },
        ],
      },
    },
    {
      path: '/sessions',
      name: 'sessions',
      component: () => import('@/views/settings/sessions/Index.vue'),
      meta: {
        pageTitle: 'Sesiones de Usuario',
        requiresAuth: true,
        permission: 0,
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    // SETTINGS

    /***Connections */
    {
      path: '/settings/connections',
      name: 'settings-connections',
      component: () => import('@/views/settings/connections/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 6,
        pageTitle: 'Gestión de Conexiones',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Conexiones de empresas',
            active: true,
          },
        ],
      },
    },

    {
      path: '/settings/roles',
      name: 'settings-roles',
      component: () => import('@/views/settings/roles/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 2,
        pageTitle: 'Gestión de Roles',        
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Roles de Usuario',
            active: true,
          },
        ],
      },
    },

    {
      path: '/settings/permissions',
      name: 'settings-permissions',
      component: () => import('@/views/settings/permissions/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 4,
        pageTitle: 'Gestión de Permisos',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Permisos del Sistema',
            active: true,
          },
        ],
      },
    },

    {
      path: '/settings/users',
      name: 'settings-users',
      component: () => import('@/views/settings/users/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 3,
        pageTitle: 'Gestión de Usuarios',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Usuarios del Sistema',
            active: true,
          },
        ],
      },
    },

    {
      path: '/settings/app',
      name: 'settings-app',
      component: () => import('@/views/settings/setting/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 3,
        pageTitle: 'Configuraciones',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Configuración',
            active: true,
          },
        ],
      },
    },

    {
      path: '/settings/relation-articles',
      name: 'settings-relation-articles',
      component: () => import('@/views/settings/relations/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 39,
        pageTitle: 'Relación de Artículos',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Configuración',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings/relation-prices',
      name: 'settings-relation-prices',
      component: () => import('@/views/settings/relations/Prices.vue'),
      meta: {
        requiresAuth: true,
        permission: 39,
        pageTitle: 'Relación de Precios',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Configuración',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings/relation-lines',
      name: 'settings-relation-lines',
      component: () => import('@/views/settings/relations/LinesArticles.vue'),
      meta: {
        requiresAuth: true,
        permission: 39,
        pageTitle: 'Lineas Artículos',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Configuración',
            active: true,
          },
        ],
      },
    },

    // PURCHASES
    {
      path: '/apps/purchases',
      name: 'apps-purchase',
      component: () => import('@/views/pages/purchases/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 5,
        pageTitle: 'Gestión de Compras',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Compras',
            active: true,
          },
        ],
      },
    },

    //
    {
      path: '/apps/catalogs/operators',
      name: 'apps-operators',
      component: () => import('@/views/pages/catalogs/operators/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 27,
        pageTitle: 'Gestión de Operadores',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Operadores',
            active: true,
          },
        ],
      },
    },

    {
      path: '/apps/catalogs/trucks',
      name: 'apps-trucks',
      component: () => import('@/views/pages/catalogs/trucks/Index.vue'),
      meta: {
        requiresAuth: true,
        permission: 27,
        pageTitle: 'Gestión de Camiones',
        breadcrumb: [
          {
            text: 'Dashboard',
            to: "/",
          },
          {
            text: 'Camiones',
            active: true,
          },
        ],
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
